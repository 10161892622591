<template>
  <Card>
    <template #header>
      <div
        class="d-flex align-items-center justify-content-between"
        style="width: 100%"
      >
        Yer Gösterimi
      </div>
    </template>

    <div class="row">
      <div class="col-12">
        <b-form-group class="add-style">
          <label for="ilAdi">Emlak Kodu </label>
          <b-form-input type="text" id="emlakKodu" required />
        </b-form-group>
      </div>
      <!-- sahibi -->
      <!-- <div class="col-12">
          <div class="form-group">
            <label  for="yetkiBelgeNo">Sahibi </label>
            <select class="form-control" id="exampleFormControlSelect1">
              <option selected>Seçiniz...</option>
              <option>ŞEKERBANK T.A.Ş.</option>
              <option>Odeabank</option>
              <option>VakıfBank</option>
              <option>Alternatif Bank</option>
              <option>Anadolubank</option>
              <option>Burgan Bank</option>
              <option>Fibabanka</option>
              <option>İstanbul Varlık</option>
              <option>Perabank</option>
              <option>TÜRKİYE FİNANS KATILIM BANKASI A.Ş.</option>
              <option>ING BANK A.Ş.</option>
              <option>Re-Pie Portföy Yönetimi A.Ş.</option>
              <option>Sahibinden</option>
              <option>Murat Eser</option>
              <option>ZİYA EREN</option>
              <option>TÜRKİYE İŞ BANKASI</option>
              <option>TÜRKİYE EMLAK KATILIM BANKASI A.Ş.</option>
              <option>YAPI VE KREDİ BANKASI A.Ş</option>
            </select>
          </div>
        </div> -->
      <div class="from-group col-12">
        <label style="font-size: 13px; font-weight: 400">Sahibi *</label>
        <MultiSelect :options="options" :multiple="false" />
      </div>
    </div>
    <!-- il -->
    <div class="col-12 p-0 ">
      <div class="form-group">
        <label for="yetkiBelgeNo">İl </label>
        <select
          class="form-control"
          id="exampleFormControlSelect1"
          @change="countyChange($event)"
        >
          <option
            v-for="iller in counties"
            :key="iller.plaka"
            :value="iller.plaka"
          >
            {{ iller.il }}
          </option>
        </select>
      </div>
    </div>
    <!-- ilçe -->
    <div class="col-12 p-0 ">
      <div class="form-group">
        <label for="yetkiBelgeNo">İlçe </label>
        <select class="form-control">
          <option value="" selected>Seçiniz</option>
          <option v-for="(ilceler, key) in districts" :key="key" :value="key">
            {{ ilceler }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-12 p-0 ">
      <b-form-group class="add-style">
        <label for="yerBaslangic">Yer Gösterilme Tarihi - Başlangıç </label>
        <b-form-input type="date" id="emlakKodu" required />
      </b-form-group>
    </div>
    <div class="col-12 p-0 ">
      <b-form-group class="add-style">
        <label for="yerBitis">Yer Gösterilme Tarihi - Bitiş </label>
        <b-form-input type="date" id="emlakKodu" required />
      </b-form-group>
    </div>
    <div class="col-12 p-0 ">
      <b-form-group class="add-style">
        <label for="randevuBaslangic"
          >Randevu Oluşturulma Tarihi - Başlangıç
        </label>
        <b-form-input type="date" id="emlakKodu" required />
      </b-form-group>
    </div>
    <div class="col-12 p-0 ">
      <b-form-group class="add-style">
        <label for="randevuBitis">Randevu Oluşturulma Tarihi - Bitiş </label>
        <b-form-input type="date" id="emlakKodu" required />
      </b-form-group>
    </div>
    <button class="saveButton mx-auto " @click="addRow()">Excel Al</button>
  </Card>
</template>

<script>
export default {
  data() {
    return {
      options: [
        { text: "Şekerbank", value: "1" },
        { text: "Odeobank", value: "2" },
        { text: "Vakıfbank", value: "3" },
      ],

      districts: [],
      counties: [
        {
          il: "Adana",
          plaka: 1,
          ilceleri: [
            "Aladağ",
            "Ceyhan",
            "Çukurova",
            "Feke",
            "İmamoğlu",
            "Karaisalı",
            "Karataş",
            "Kozan",
            "Pozantı",
            "Saimbeyli",
            "Sarıçam",
            "Seyhan",
            "Tufanbeyli",
            "Yumurtalık",
            "Yüreğir",
          ],
        },
        {
          il: "Adıyaman",
          plaka: 2,
          ilceleri: [
            "Besni",
            "Çelikhan",
            "Gerger",
            "Gölbaşı",
            "Kahta",
            "Merkez",
            "Samsat",
            "Sincik",
            "Tut",
          ],
        },
        {
          il: "Afyonkarahisar",
          plaka: 3,
          ilceleri: [
            "Başmakçı",
            "Bayat",
            "Bolvadin",
            "Çay",
            "Çobanlar",
            "Dazkırı",
            "Dinar",
            "Emirdağ",
            "Evciler",
            "Hocalar",
            "İhsaniye",
            "İscehisar",
            "Kızılören",
            "Merkez",
            "Sandıklı",
            "Sinanpaşa",
            "Sultandağı",
            "Şuhut",
          ],
        },
        {
          il: "Ağrı",
          plaka: 4,
          ilceleri: [
            "Diyadin",
            "Doğubayazıt",
            "Eleşkirt",
            "Hamur",
            "Merkez",
            "Patnos",
            "Taşlıçay",
            "Tutak",
          ],
        },
        {
          il: "Amasya",
          plaka: 5,
          ilceleri: [
            "Göynücek",
            "Gümüşhacıköy",
            "Hamamözü",
            "Merkez",
            "Merzifon",
            "Suluova",
            "Taşova",
          ],
        },
        {
          il: "Ankara",
          plaka: 6,
          ilceleri: [
            "Altındağ",
            "Ayaş",
            "Bala",
            "Beypazarı",
            "Çamlıdere",
            "Çankaya",
            "Çubuk",
            "Elmadağ",
            "Güdül",
            "Haymana",
            "Kalecik",
            "Kızılcahamam",
            "Nallıhan",
            "Polatlı",
            "Şereflikoçhisar",
            "Yenimahalle",
            "Gölbaşı",
            "Keçiören",
            "Mamak",
            "Sincan",
            "Kazan",
            "Akyurt",
            "Etimesgut",
            "Evren",
            "Pursaklar",
          ],
        },
        {
          il: "Antalya",
          plaka: 7,
          ilceleri: [
            "Akseki",
            "Alanya",
            "Elmalı",
            "Finike",
            "Gazipaşa",
            "Gündoğmuş",
            "Kaş",
            "Korkuteli",
            "Kumluca",
            "Manavgat",
            "Serik",
            "Demre",
            "İbradı",
            "Kemer",
            "Aksu",
            "Döşemealtı",
            "Kepez",
            "Konyaaltı",
            "Muratpaşa",
          ],
        },
        {
          il: "Artvin",
          plaka: 8,
          ilceleri: [
            "Ardanuç",
            "Arhavi",
            "Merkez",
            "Borçka",
            "Hopa",
            "Şavşat",
            "Yusufeli",
            "Murgul",
          ],
        },
        {
          il: "Aydın",
          plaka: 9,
          ilceleri: [
            "Merkez",
            "Bozdoğan",
            "Efeler",
            "Çine",
            "Germencik",
            "Karacasu",
            "Koçarlı",
            "Kuşadası",
            "Kuyucak",
            "Nazilli",
            "Söke",
            "Sultanhisar",
            "Yenipazar",
            "Buharkent",
            "İncirliova",
            "Karpuzlu",
            "Köşk",
            "Didim",
          ],
        },
        {
          il: "Balıkesir",
          plaka: 10,
          ilceleri: [
            "Altıeylül",
            "Ayvalık",
            "Merkez",
            "Balya",
            "Bandırma",
            "Bigadiç",
            "Burhaniye",
            "Dursunbey",
            "Edremit",
            "Erdek",
            "Gönen",
            "Havran",
            "İvrindi",
            "Karesi",
            "Kepsut",
            "Manyas",
            "Savaştepe",
            "Sındırgı",
            "Gömeç",
            "Susurluk",
            "Marmara",
          ],
        },
        {
          il: "Bilecik",
          plaka: 11,
          ilceleri: [
            "Merkez",
            "Bozüyük",
            "Gölpazarı",
            "Osmaneli",
            "Pazaryeri",
            "Söğüt",
            "Yenipazar",
            "İnhisar",
          ],
        },
        {
          il: "Bingöl",
          plaka: 12,
          ilceleri: [
            "Merkez",
            "Genç",
            "Karlıova",
            "Kiğı",
            "Solhan",
            "Adaklı",
            "Yayladere",
            "Yedisu",
          ],
        },
        {
          il: "Bitlis",
          plaka: 13,
          ilceleri: [
            "Adilcevaz",
            "Ahlat",
            "Merkez",
            "Hizan",
            "Mutki",
            "Tatvan",
            "Güroymak",
          ],
        },
        {
          il: "Bolu",
          plaka: 14,
          ilceleri: [
            "Merkez",
            "Gerede",
            "Göynük",
            "Kıbrıscık",
            "Mengen",
            "Mudurnu",
            "Seben",
            "Dörtdivan",
            "Yeniçağa",
          ],
        },
        {
          il: "Burdur",
          plaka: 15,
          ilceleri: [
            "Ağlasun",
            "Bucak",
            "Merkez",
            "Gölhisar",
            "Tefenni",
            "Yeşilova",
            "Karamanlı",
            "Kemer",
            "Altınyayla",
            "Çavdır",
            "Çeltikçi",
          ],
        },
        {
          il: "Bursa",
          plaka: 16,
          ilceleri: [
            "Gemlik",
            "İnegöl",
            "İznik",
            "Karacabey",
            "Keles",
            "Mudanya",
            "Mustafakemalpaşa",
            "Orhaneli",
            "Orhangazi",
            "Yenişehir",
            "Büyükorhan",
            "Harmancık",
            "Nilüfer",
            "Osmangazi",
            "Yıldırım",
            "Gürsu",
            "Kestel",
          ],
        },
        {
          il: "Çanakkale",
          plaka: 17,
          ilceleri: [
            "Ayvacık",
            "Bayramiç",
            "Biga",
            "Bozcaada",
            "Çan",
            "Merkez",
            "Eceabat",
            "Ezine",
            "Gelibolu",
            "Gökçeada",
            "Lapseki",
            "Yenice",
          ],
        },
        {
          il: "Çankırı",
          plaka: 18,
          ilceleri: [
            "Merkez",
            "Çerkeş",
            "Eldivan",
            "Ilgaz",
            "Kurşunlu",
            "Orta",
            "Şabanözü",
            "Yapraklı",
            "Atkaracalar",
            "Kızılırmak",
            "Bayramören",
            "Korgun",
          ],
        },
        {
          il: "Çorum",
          plaka: 19,
          ilceleri: [
            "Alaca",
            "Bayat",
            "Merkez",
            "İskilip",
            "Kargı",
            "Mecitözü",
            "Ortaköy",
            "Osmancık",
            "Sungurlu",
            "Boğazkale",
            "Uğurludağ",
            "Dodurga",
            "Laçin",
            "Oğuzlar",
          ],
        },
        {
          il: "Denizli",
          plaka: 20,
          ilceleri: [
            "Acıpayam",
            "Buldan",
            "Çal",
            "Çameli",
            "Çardak",
            "Çivril",
            "Merkez",
            "Merkezefendi",
            "Pamukkale",
            "Güney",
            "Kale",
            "Sarayköy",
            "Tavas",
            "Babadağ",
            "Bekilli",
            "Honaz",
            "Serinhisar",
            "Baklan",
            "Beyağaç",
            "Bozkurt",
          ],
        },
        {
          il: "Diyarbakır",
          plaka: 21,
          ilceleri: [
            "Kocaköy",
            "Çermik",
            "Çınar",
            "Çüngüş",
            "Dicle",
            "Ergani",
            "Hani",
            "Hazro",
            "Kulp",
            "Lice",
            "Silvan",
            "Eğil",
            "Bağlar",
            "Kayapınar",
            "Sur",
            "Yenişehir",
            "Bismil",
          ],
        },
        {
          il: "Edirne",
          plaka: 22,
          ilceleri: [
            "Merkez",
            "Enez",
            "Havsa",
            "İpsala",
            "Keşan",
            "Lalapaşa",
            "Meriç",
            "Uzunköprü",
            "Süloğlu",
          ],
        },
        {
          il: "Elazığ",
          plaka: 23,
          ilceleri: [
            "Ağın",
            "Baskil",
            "Merkez",
            "Karakoçan",
            "Keban",
            "Maden",
            "Palu",
            "Sivrice",
            "Arıcak",
            "Kovancılar",
            "Alacakaya",
          ],
        },
        {
          il: "Erzincan",
          plaka: 24,
          ilceleri: [
            "Çayırlı",
            "Merkez",
            "İliç",
            "Kemah",
            "Kemaliye",
            "Refahiye",
            "Tercan",
            "Üzümlü",
            "Otlukbeli",
          ],
        },
        {
          il: "Erzurum",
          plaka: 25,
          ilceleri: [
            "Aşkale",
            "Çat",
            "Hınıs",
            "Horasan",
            "İspir",
            "Karayazı",
            "Narman",
            "Oltu",
            "Olur",
            "Pasinler",
            "Şenkaya",
            "Tekman",
            "Tortum",
            "Karaçoban",
            "Uzundere",
            "Pazaryolu",
            "Köprüköy",
            "Palandöken",
            "Yakutiye",
            "Aziziye",
          ],
        },
      ],
    };
  },
  methods: {
    countyChange(e) {
      let filteredData = this.counties.filter(
        (county) => county.plaka == e.target.value
      );
      this.districts = filteredData[0].ilceleri;
    },
    
  },
};
</script>

